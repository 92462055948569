import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/images/logo-licar.png';

const Header = () => {
  return (
    <header className="app-header">
      <div className="header-left">
        <Link to="/">
          <img src={logo} alt="Logo" className="app-logo" />
        </Link>
        <h1>Eventos Fundacion LICAR</h1>
      </div>
      <div className="header-right">
        <nav>
          <Link to="/" className="nav-link">Inicio</Link>
          <Link to="/registro" className="nav-link">Registro</Link>
          {/* <Link to="/listadousuarios" className="nav-link">Lista de Usuarios</Link> */}
          <Link to="/certificados" className="nav-link certificados-link">Certificados</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
