import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ReactDOM from 'react-dom/client';
import './index.css';
import EventoComponent from './pages/event/event';
import reportWebVitals from './reportWebVitals';
import Footer from './pages/Shared/Footer';
import Header from './pages/Shared/Header';
import Register from './pages/Registro/registro';
import CertificadoComponent from './pages/Cerificados/Certificate';
import OsteoProfilesTable from './pages/ListUser/ListUser';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/registro" element={<Register />} />
        <Route path="/certificados" element={<CertificadoComponent />} />
        <Route path="/listadousuarios" element={<OsteoProfilesTable />} />
        <Route path="/" element={<EventoComponent />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
