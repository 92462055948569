import React, { useState, useEffect } from "react";
import {
  auth,
  db,
  doc,
  setDoc,
  getDataFromSecondInstanceCollection,
} from "../../services/firebaseServices";
import { createUserWithEmailAndPassword } from "firebase/auth";

import Swal from "sweetalert2";
import "./registro.css";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tratrante, setTratrante] = useState("");
  const [eps, setEps] = useState("");
  const [consent, setConsent] = useState("");
  const [difusion, setDifusion] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [medicalSpeciality, setMedicalSpeciality] = useState("");
  const [studentStatus, setStudentStatus] = useState(true);
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSponsored, setIsSponsored] = useState("");
  const [sponsored, setSponsored] = useState("");
  const [errors, setErrors] = useState({});
  const [customSpeciality, setCustomSpeciality] = useState("");
  const [customLab, setCustomLab] = useState("");
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState(""); // Ciudad
  const [confirmAttendance, setConfirmAttendance] = useState(false); // Confirmar asistencia
  const [availableSlots, setAvailableSlots] = useState(true);
  const [eventLocation, setEventLocation] = useState("");
  const labToSend = isSponsored === "Otro" ? customLab : isSponsored;
  const specialityToSend =
    medicalSpeciality === "Otro" ? customSpeciality : medicalSpeciality;

  const [remainingSlots, setRemainingSlots] = useState(200);
  const [hasConsent, setHasConsent] = useState(false);

  const [spotsLeft, setSpotsLeft] = useState(null);
  const [loadingAvailability, setLoadingAvailability] = useState(true);

  const validateForm = () => {
    let tempErrors = {};

    if (!fullName) tempErrors.fullName = "El nombre completo es requerido.";
    if (!lastName) tempErrors.lastName = "El apellido es requerido.";
    if (!city) tempErrors.city = "La ciudad es requerida.";
    if (!documentNumber)
      tempErrors.documentNumber = "El número de documento es requerido.";
    if (!medicalSpeciality)
      tempErrors.medicalSpeciality = "La especialidad médica es requerida.";
    if (!address) tempErrors.address = "La dirección es requerida.";
    if (!email) tempErrors.email = "El email es requerido.";
    if (!phoneNumber)
      tempErrors.phoneNumber = "El número de teléfono es requerido.";
    if (!password) tempErrors.password = "La contraseña es requerida.";
    if (password !== confirmPassword)
      tempErrors.confirmPassword = "Las contraseñas no coinciden.";
    if (!confirmAttendance)
      tempErrors.confirmAttendance =
        "La confirmación de asistencia es requerida.";
    if (!hasConsent) {
      errors.hasConsent = "Debes aceptar el consentimiento informado.";
    }

    setErrors(tempErrors);
    return tempErrors;
  };

  useEffect(() => {
    async function fetchAvailability() {
      let fetchedData = [];
      fetchedData = await getDataFromSecondInstanceCollection("UserProfiles");

      // Contando los que confirmaron asistencia
      const confirmedCount = fetchedData.filter(
        (user) => user.confirmAttendance === "Si"
      ).length;

      // Contando los patrocinados que confirmaron asistencia
      const sponsorsCount = fetchedData.filter(
        (user) =>
          user.confirmAttendance === "Si" &&
          user.isSponsored !== "No está patrocinado"
      ).length;

      const totalRegistered = confirmedCount + sponsorsCount;

      setRemainingSlots(200 - totalRegistered);
      setLoadingAvailability(false);
    }

    fetchAvailability();
  }, []);

  const handleBlur = (field) => {
    let tempErrors = { ...errors };
    if (!field) {
      tempErrors[field] = `El campo ${field} es obligatorio.`;
    } else {
      delete tempErrors[field];
    }
    setErrors(tempErrors);
  };

  const arePasswordsEqual = () => {
    return password === confirmPassword;
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (!arePasswordsEqual()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Las contraseñas no coinciden.",
      }));
    } else {
      setErrors((prevErrors) => {
        const { confirmPassword, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const saveUserProfile = async (uid) => {
    const userRef = doc(db, "OsteoProfiles", uid);
    const userProfile = {
      fullName,
      lastName,
      documentNumber,
      medicalSpeciality: specialityToSend,
      studentStatus,
      address,
      phoneNumber,
      email,
      city,
      tratrante,
      difusion,
      eps,
      consent,
      confirmAttendance,
      isSponsored: sponsored === "Si" ? isSponsored : "No está patrocinado",
      lab: sponsored === "Si" ? customLab : "No está patrocinado",
    };
    try {
      await setDoc(userRef, userProfile);
    } catch (error) {
      throw error; // Esto es importante para que el error se propague y lo puedas atrapar en handleRegister
    }
    console.log("Fin de saveUserProfile");
  };

  const handleRegister = async () => {
    setLoading(true);

    const formErrors = validateForm();

    if (!arePasswordsEqual()) {
      Swal.fire("Error", "Las contraseñas no coinciden.", "error");
      setLoading(false);
      return;
    }

    if (Object.keys(formErrors).length === 0) {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        const uid = userCredential.user.uid;
        await saveUserProfile(uid);
        const emailData = {
          email,
          password,
          fullName,
          lastName,
          documentNumber,
          medicalSpeciality: specialityToSend,
          studentStatus,
          address,
          phoneNumber,
          tratrante,
          difusion,
          eps,
          consent,
          isSponsored: sponsored === "Si" ? isSponsored : "No está patrocinado",
          lab: sponsored === "Si" ? customLab : "No está patrocinado",
          city,
          confirmAttendance,
          sponsored,
        };

        await fetch(
          "https://us-central1-diamundialosteoporosis.cloudfunctions.net/sendEmail",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(emailData),
          }
        );

        Swal.fire(
          "¡Éxito!",
          "Registro exitoso. Revisa tu correo para más detalles.",
          "success"
        );

        resetForm();
      } catch (error) {
        Swal.fire("Error", "Error al registrarse: " + error.message, "error");
      }
    }

    setLoading(false);
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setFullName("");
    setLastName("");
    setDocumentNumber("");
    setMedicalSpeciality("");
    setAddress("");
    setPhoneNumber("");
    setIsSponsored("");
    setSponsored("");
    setTratrante("");
    setDifusion("");
    setEps("");
    setConsent("");
    setCustomSpeciality("");
    setCustomLab("");
    setCity(""); // Reiniciar ciudad
    setConfirmAttendance(false); // Reiniciar confirmación de asistencia
  };

  const noSlotsStyle = {
    color: "red",
    fontWeight: "bold",
    fontSize: "1.5rem",
    textAlign: "center",
    margin: "20px 0",
  };


  return (
    <>
        <>
          {loadingAvailability ? (
            <p>Cargando disponibilidad...</p>
          ) : (
            <div className="background">
              <div className="App">
                <header className="App-header">
                  <h2>Cupos disponibles: {remainingSlots}</h2>

                  {remainingSlots <= 0 ? (
                    <div style={noSlotsStyle}>
                      <p>
                        No hay cupos disponibles para la asistencia presencial.
                      </p>
                      <p>
                        Puedes ver el evento en{" "}
                        <a href="http://eventos.fundacionlicar.com">
                          eventos.fundacionlicar.com
                        </a>
                      </p>
                    </div>
                  ) : (
                    <>
                      <p>
                        Quedan {remainingSlots} cupos para asistencia.
                      </p>

                      {loading ? (
                        <p>Creando su registro, por favor espere...</p>
                      ) : (
                        <>
                          {/* <img
                            src="#"
                            className="App-logo"
                            alt="logo"
                          /> */}
                          {/* Aquí iría el resto del formulario para Barranquilla */}
                          <input
                            type="text"
                            placeholder="Nombre completo"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            onBlur={() => handleBlur("fullName")}
                          />
                          {errors.fullName && (
                            <span style={{ color: "red" }}>
                              {errors.fullName}
                            </span>
                          )}

                          <input
                            type="text"
                            placeholder="Apellido"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            onBlur={() => handleBlur("lastName")}
                          />
                          {errors.lastName && (
                            <span style={{ color: "red" }}>
                              {errors.lastName}
                            </span>
                          )}

                          <input
                            type="text"
                            placeholder="Número de documento"
                            value={documentNumber}
                            onChange={(e) => setDocumentNumber(e.target.value)}
                            onBlur={() => handleBlur("documentNumber")}
                          />
                          {errors.documentNumber && (
                            <span style={{ color: "red" }}>
                              {errors.documentNumber}
                            </span>
                          )}

                          <select
                            value={medicalSpeciality}
                            onChange={(e) =>
                              setMedicalSpeciality(e.target.value)
                            }
                            onBlur={() => handleBlur("medicalSpeciality")}
                          >
                            <option value="">
                              Selecciona tu enfermedad reumática
                            </option>
                            <option value="osteoporosis">Osteoporosis</option>
                            <option value="osteopenia">Osteopenia</option>
                            <option value="Desmineralización ósea">
                              Desmineralización ósea
                            </option>
                            <option value="Hipertiroidismo">
                              Hipertiroidismo
                            </option>
                            <option value="Hipotiroidismo">
                              Hipotiroidismo
                            </option>
                            <option value="Artritis Reumatoidea">
                              Artritis Reumatoidea
                            </option>
                            <option value="Progesterona">Progesterona</option>
                            <option value="Testosterona baja">
                              Testosterona baja
                            </option>
                            <option value="fractura osteoporótica">
                              fractura osteoporótica
                            </option>
                            <option value="Otro">Otro</option>
                          </select>

                          {medicalSpeciality === "Otro" && (
                            <input
                              type="text"
                              placeholder="Escribe tu enfermedad"
                              value={customSpeciality}
                              onChange={(e) =>
                                setCustomSpeciality(e.target.value)
                              }
                            />
                          )}
                          {errors.medicalSpeciality && (
                            <span style={{ color: "red" }}>
                              {errors.medicalSpeciality}
                            </span>
                          )}
                          {/* Campo Medico Tratante */}
                          <select
                            value={difusion}
                            onChange={(e) => setDifusion(e.target.value)} // Aquí estaba el error
                            onBlur={() => handleBlur("difusion")}
                          >
                            <option value="">
                              ¿Como se entero del evento?
                            </option>
                            <option value="Médico tratante">
                              Médico tratante
                            </option>
                            <option value="Redes">Redes</option>
                            <option value="Whatsapp">Whatsapp</option>
                            <option value="Llamada telefónica">
                              Llamada telefónica
                            </option>
                            <option value="Mensaje de texto">
                              Mensaje de texto
                            </option>
                            <option value="Messenger">Messenger</option>
                          </select>

                          {/* Campo Medico Tratante */}
                          <select
                            value={tratrante}
                            onChange={(e) => setTratrante(e.target.value)}
                            onBlur={() => handleBlur("tratrante")}
                          >
                            <option value="">¿Quien le trata?</option>
                            <option value="Médico tratante">
                              Médico tratante
                            </option>
                            <option value="Reumatologo">Reumatólogo</option>
                            <option value="Ortopedista">Ortopedista</option>
                            <option value="Endocrinologo">Endocrinologo</option>
                            <option value="otro">Otro</option>
                          </select>
                          <input
                            type="text"
                            placeholder="Escriba su eps"
                            value={eps}
                            onChange={(e) => setEps(e.target.value)}
                            onBlur={() => handleBlur("eps")}
                          />
                          {/* Campo Direccion*/}
                          <input
                            type="text"
                            placeholder="Dirección"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            onBlur={() => handleBlur("address")}
                          />
                          {errors.address && (
                            <span style={{ color: "red" }}>
                              {errors.address}
                            </span>
                          )}
                          {/* Campo Ciudad */}
                          <input
                            type="text"
                            placeholder="Ciudad"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            onBlur={() => handleBlur("city")}
                          />
                          {errors.city && (
                            <span style={{ color: "red" }}>{errors.city}</span>
                          )}

                          {/* Campo Confirmar Asistencia */}
                          <div
                            className={
                              errors.confirmAttendance ? "errorHighlight" : ""
                            }
                          >
                            <label>
                              <input
                                type="radio"
                                value="Si"
                                checked={confirmAttendance === "Si"}
                                onChange={(e) =>
                                  setConfirmAttendance(e.target.value)
                                }
                              />
                              Sí
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="No"
                                checked={confirmAttendance === "No"}
                                onChange={(e) =>
                                  setConfirmAttendance(e.target.value)
                                }
                              />
                              No
                            </label>
                            <br />
                            Confirmar asistencia presencial
                            {errors.confirmAttendance && (
                              <span>{errors.confirmAttendance}</span>
                            )}
                          </div>

                          <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => handleBlur("email")}
                          />
                          {errors.email && (
                            <span style={{ color: "red" }}>{errors.email}</span>
                          )}

                          <input
                            type="text"
                            placeholder="Número de teléfono"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            onBlur={() => handleBlur("phoneNumber")}
                          />
                          {errors.phoneNumber && (
                            <span style={{ color: "red" }}>
                              {errors.phoneNumber}
                            </span>
                          )}
                          <select
                            value={sponsored}
                            onChange={(e) => setSponsored(e.target.value)}
                            onBlur={() => handleBlur("sponsored")}
                          >
                            <option value="">¿usted lleva acompañante?</option>
                            <option value="Si">Sí</option>
                            <option value="No">No</option>
                          </select>

                          {sponsored === "Si" && (
                            <>
                              <select
                                value={isSponsored}
                                onChange={(e) => setIsSponsored(e.target.value)}
                                onBlur={() => handleBlur("isSponsored")}
                              >
                                <option value="">
                                  Selecciona tu tipo de acompañante
                                </option>
                                <option value="cuidador">cuidador</option>
                                <option value="Enfermero">Enfermero</option>
                                <option value="Familiar">Familiar</option>
                                <option value="Familiar con enfermedad reumatica">
                                  Familiar con enfermedad reumatica.
                                </option>

                                <option value="Otro">Otro</option>
                              </select>
                              {isSponsored && (
                                <input
                                  type="text"
                                  placeholder="Escribe el nombre de tu acompañante"
                                  value={customLab}
                                  onChange={(e) => setCustomLab(e.target.value)}
                                />
                              )}
                              {errors.isSponsored && (
                                <span style={{ color: "red" }}>
                                  {errors.isSponsored}
                                </span>
                              )}
                            </>
                          )}
                          <div className="consentWrapper">
                            <input
                              type="checkbox"
                              id="consent"
                              value={consent}
                              checked={hasConsent}
                              onChange={(e) => setHasConsent(e.target.checked)}
                            />
                            <label htmlFor="consent">
                              He leído y acepto el consentimiento informado de
                              acuerdo con la Ley 1581 de 2012 y el Decreto 1377
                              de 2013 sobre Protección de Datos Personales de
                              Colombia.
                            </label>

                            {errors.hasConsent && (
                              <span style={{ color: "red" }}>
                                {errors.hasConsent}
                              </span>
                            )}
                          </div>

                          <hr />

                          <input
                            type="password"
                            placeholder="Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {errors.password && (
                            <span style={{ color: "red" }}>
                              {errors.password}
                            </span>
                          )}
                          <input type="hidden" value={studentStatus} />

                          <input
                            type="password"
                            placeholder="Confirmar Contraseña"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            // onChange={handleConfirmPasswordChange}
                          />
                          {errors.confirmPassword && (
                            <span style={{ color: "red" }}>
                              {errors.confirmPassword}
                            </span>
                          )}
                          <button
                            onClick={handleRegister}
                            disabled={
                              Object.keys(errors).length > 0 ||
                              !hasConsent ||
                              remainingSlots <= 0
                            }
                          >
                            Regístrate
                          </button>
                          {/* [Fin del formulario] */}
                        </>
                      )}
                    </>
                  )}
                </header>
              </div>
            </div>
          )}
        </>
    
    </>
  );
}

export default Register;
