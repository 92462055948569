import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import './ListUser.css';
const OsteoProfilesTable = () => {
  const [profiles, setProfiles] = useState([]);

  // Función para obtener los datos de Firestore
  const fetchProfiles = async () => {
    try {
      // Creamos una consulta para ordenar los datos alfabéticamente por una propiedad, ej. 'fullName'
      const q = query(collection(db, 'OsteoProfiles'), orderBy('fullName'));
      const querySnapshot = await getDocs(q);
      const profilesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProfiles(profilesData);
    } catch (error) {
      console.error("Error fetching profiles: ", error);
    }
  };

  // Hook de efecto para obtener los datos al montar el componente
  useEffect(() => {
    fetchProfiles();
  }, []);

  // Renderizar la tabla con los datos de los perfiles
  return (
    <div>
      <h2>Osteo Profiles</h2>
      <table>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Last Name</th>
            <th>Document Number</th>
            <th>Address</th>
            <th>City</th>
            <th>Diffusion</th>
            <th>Email</th>
            <th>EPS</th>
            <th>Acompañante</th>
            <th>Nombre acompañante</th>
            <th>Medical Specialty</th>
            <th>Phone Number</th>
            <th>Treater</th>
            <th>Confirm Attendance</th>
          </tr>
        </thead>
        <tbody>
          {profiles.map((profile) => (
            <tr key={profile.id}>
              <td>{profile.fullName}</td>
              <td>{profile.lastName}</td>
              <td>{profile.documentNumber}</td>
              <td>{profile.address}</td>
              <td>{profile.city}</td>
              <td>{profile.difusion}</td>
              <td>{profile.email}</td>
              <td>{profile.eps}</td>
              <td>{profile.isSponsored}</td>
              <td>{profile.lab}</td>
              <td>{profile.medicalSpeciality}</td>
              <td>{profile.phoneNumber}</td>

              <td>{profile.tratrante}</td>
              <td>{profile.confirmAttendance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OsteoProfilesTable;
