import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebaseConfig";
import { collection, addDoc, query, onSnapshot, orderBy } from "firebase/firestore";
import "./chat.css";

const Chat = () => {
  const [nombre, setNombre] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [mensajes, setMensajes] = useState([]);
  const [isNombreSet, setIsNombreSet] = useState(false);
  const chatMessagesRef = useRef(null); 

  useEffect(() => {
    const q = query(collection(db, "chat"), orderBy("timestamp"));
    const unsub = onSnapshot(q, (snapshot) => {
      const mensajesData = [];
      snapshot.forEach((doc) => {
        mensajesData.push(doc.data());
      });
      setMensajes(mensajesData);

      // Aplicar un breve retraso antes de establecer el desplazamiento
      setTimeout(() => {
          if (chatMessagesRef.current) {
              chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
          }
      }, 100);
    });

    return () => unsub(); // Limpiar el listener al desmontar el componente
  }, [db]);

  const enviarMensaje = async () => {
    if (mensaje.trim()) {
      await addDoc(collection(db, "chat"), {
        usuario: nombre,
        mensaje: mensaje,
        timestamp: new Date(),
      });
      setMensaje("");
    }
  };

  function calculateTimeElapsed(date) {
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    if (diffInSeconds < 60) return "hace " + diffInSeconds + " segundos";
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return "hace " + diffInMinutes + " minutos";
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return "hace " + diffInHours + " horas";
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return "hace " + diffInDays + " días";
    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 52) return "hace " + diffInWeeks + " semanas";
    const diffInYears = Math.floor(diffInWeeks / 52);
    return "hace " + diffInYears + " años";
  }

  return (
    <div>
      {!isNombreSet ? (
        <div className="input-container">
          <input
            type="text"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            placeholder="Escribe tu nombre"
          />
          <button onClick={() => setIsNombreSet(true)}>Entrar</button>
        </div>
      ) : (
        <div>
          <div className="chat-container">
            <div className="chat-messages" ref={chatMessagesRef}>
                {mensajes.map((msg, index) => (
                    <div key={index} className={`message ${msg.usuario === nombre ? "mine" : "others"}`}>
                        <strong>{msg.usuario}</strong>: {msg.mensaje}
                        <div className="time-elapsed">
                            {calculateTimeElapsed(msg.timestamp.toDate())}
                        </div>
                    </div>
                ))}
            </div>
          </div>

          <div className="input-container">
    <input
        type="text"
        value={mensaje}
        onChange={(e) => setMensaje(e.target.value)}
        placeholder="Escribe un mensaje"
    />
    <button onClick={enviarMensaje}>Enviar</button>
</div>
        </div>
      )}
    </div>
  );
};

export default Chat;
