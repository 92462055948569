import React from "react";
import { Link } from "react-router-dom"; // Importa Link de react-router-dom
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        {/* Contenido previo del footer aquí */}
        <hr />
        <div className="footer-bottom">
          {/* Añade el enlace al listado de usuarios aquí */}
        
          <p>&copy; 2023 Fundacion LICAR. Todos los derechos reservados. 
            {/* <Link to="/listadousuarios">List</Link>  */}
            </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
