import React from 'react';
import { Link } from 'react-router-dom'; 
import "./SocialLinks.css";

// Importación de las imágenes
import facebookLogo from '../../assets/images/facebook.png';
import twitterLogo from '../../assets/images/twitter.png';
import linkedinLogo from '../../assets/images/linkedin.png';
import youtubeLogo from '../../assets/images/youtube.png';
import registerLogo from '../../assets/images/registrate.png';
import certificadteLogo from '../../assets/images/certificados.png';
const SocialLinks = () => {
    return (
        <div className="social-links-container">
            <div className="social-links">
                <a href="https://www.facebook.com/LICARFundare" target="_blank" rel="noopener noreferrer">
                    <img src={facebookLogo} alt="Facebook" />
                </a>
                <a href="https://twitter.com/fundacionlicar2" target="_blank" rel="noopener noreferrer">
                    <img src={twitterLogo} alt="Twitter" />
                </a>
                <a href="https://www.linkedin.com/in/fundacion-licar-0a26a8298/" target="_blank" rel="noopener noreferrer">
                    <img src={linkedinLogo} alt="LinkedIn" />
                </a>
                <a href="https://www.youtube.com/channel/UCFEvWKiMLTXH8ep_67QetsQ" target="_blank" rel="noopener noreferrer">
                    <img src={youtubeLogo} alt="YouTube" />
                </a>
               
                 <Link to="/registro">
                    <img src={registerLogo} alt="Registro al evento" />
                </Link> 
                  <Link to="/certificados" className="link">
                    <img src={certificadteLogo} alt="Certificados" />
                </Link>
            </div>
            <div className="certification-reminder">
                <p>Para obtener su certificado, es esencial que complete el proceso de registro al evento a través del botón de registro.</p>
            </div>
        </div>
    );
};

export default SocialLinks;
