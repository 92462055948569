import React, { useState, useEffect } from "react";
import { collection, getDoc, getDocs, doc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { jsPDF } from "jspdf";

import "./Certificate.css";

function CertificadoComponent() {
  const [cedula, setCedula] = useState("");
  const [certificadoURL, setCertificadoURL] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [error, setError] = useState("");
  const [localImageLoaded, setLocalImageLoaded] = useState(false);

  useEffect(() => {
    // Carga la imagen de fondo desde la URL o localmente aquí
    const imageUrl = "https://i.ibb.co/C0LkrRM/certificado-osteoporosaias1.jpg"; // Cambia la ruta según tu estructura de carpetas

    loadImageFromUrl(imageUrl)
      .then((base64Image) => {
        setBackgroundImage(base64Image);
        setLocalImageLoaded(true);
      })
      .catch((error) => {
        console.error("Error al cargar la imagen de fondo:", error);
        setLocalImageLoaded(false);
      });
  }, []);

  function loadImageFromUrl(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        resolve(canvas.toDataURL("image/jpeg"));
      };
      img.onerror = reject;
    });
  }

  const handleBuscarCedula = async () => {
    try {
      // Consultar la colección 'OsteoProfiles' para obtener los datos del usuario
      const query = collection(db, "OsteoProfiles");
      const querySnapshot = await getDocs(query);
      const usuarios = querySnapshot.docs.map((doc) => doc.data());

      // Buscar al usuario por número de cédula
      const usuarioData = usuarios.find(
        (usuario) => usuario.documentNumber === cedula
      );

      if (!usuarioData) {
        setError("Usuario no registrado en la participación del evento.");
        return;
      }

      // Si el usuario se encuentra en la base de datos, limpia el mensaje de error
      setError("");

      // Generar el PDF del certificado sin incluir el nombre del evento
      generateCertificadoPDF(usuarioData);
    } catch (error) {
      setError("Error al buscar la cédula.");
      console.error("Error al buscar la cédula:", error);
    }
  };

  // Helper function to capitalize the first letter of each word
function capitalizeWords(str) {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

const generateCertificadoPDF = (usuarioData) => {
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: [297, 210],
  }); // Tamaño carta horizontal

  if (backgroundImage && localImageLoaded) {
    doc.addImage(backgroundImage, "JPEG", 0, 0, 297, 210); // Ajusta el tamaño de la imagen de fondo según el PDF
  }

  // Estilo para el nombre y apellidos
  doc.setFont("times", "bold");
  doc.setFontSize(35); // Tamaño de fuente para el nombre y apellidos
  doc.setTextColor(0, 0, 0); // Color del texto en RGB (negro)

  // Texto para el nombre y apellidos
  const nombreCompleto = `${capitalizeWords(usuarioData.fullName)} ${capitalizeWords(usuarioData.lastName)}`;
  doc.text(nombreCompleto, 85, 115);

  // Estilo para la cédula
  doc.setFont("times", "bold");
  doc.setFontSize(16); // Tamaño de fuente para la cédula
  doc.setTextColor(0, 0, 0); // Color del texto en RGB (negro)
  doc.text("Documento:", 120, 125); // Texto adicional antes de la cédula
  doc.text(cedula, 150, 125); // Ajusta las coordenadas para posicionar la cédula

  // Guarda el PDF o muestra en una nueva ventana
  setCertificadoURL(doc.output("datauristring")); // Convierte el PDF a una URL para mostrarla en el componente
};


  return (
    <div className="certificado-container">
      <input
        className="certificado-input"
        type="text"
        placeholder="Número de Cédula"
        value={cedula}
        onChange={(e) => setCedula(e.target.value)}
      />
      <button className="certificado-button" onClick={handleBuscarCedula}>
        Buscar y Generar Certificado
      </button>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {!certificadoURL ? (
        <div className="instructions">
          <p>Para descargar tu certificado, sigue estos pasos:</p>
          <ol>
            <li>Ingresa tu número de cédula en el campo de arriba.</li>
            <li>Haz clic en el botón "Buscar y Generar Certificado".</li>
            <li>Si estás registrado en la participación del evento, tu certificado se mostrará a continuación.</li>
          </ol>
        </div>
      ) : null}

      {certificadoURL && (
        <div>
          <iframe
            src={certificadoURL}
            title="Certificado"
            width="800"
            height="500"
          ></iframe>
        </div>
      )}
    </div>
  );
}

export default CertificadoComponent;
