// EventoComponent.jsx
import React, { useState, useEffect } from 'react';
import './event.css';
import { db } from '../../firebaseConfig';  // Asegúrate de tener la ruta correcta a tu archivo de Firebase
import { doc, getDoc } from 'firebase/firestore';
import Chat from  '../chat/chat';
import  SocialLinks from '../OtherChanel/OtherChanel';
const EventoComponent = () => {
    const [eventoData, setEventoData] = useState(null);

    useEffect(() => {
        const fetchEventoData = async () => {
            const docRef = doc(db, "evento", "cMnKuomGYedfeXVAXEcw"); // Aquí estamos utilizando el ID específico que has proporcionado
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setEventoData(docSnap.data());
            } else {
                console.log("No se encontró el documento");
            }
        };

        fetchEventoData();
    }, []);

    return (
        <>
        <div className="evento-container">
            {eventoData ? (
                <>
                    <h1 className="evento-title">{eventoData.name}</h1>
                    <div className="video-chat-container">
                        <div className="video-container">
                            <div dangerouslySetInnerHTML={{ __html: eventoData.url }} />
                        </div>
                        <div className="chat-container">
                       
                            <Chat />
                        </div>
                    </div>
                </>
            ) : (
                "Cargando..."
            )}
        </div>
        <p className='parafo'> Visualiza en nuestras redes Sociales</p>
        <SocialLinks />
        </>
    );
}

export default EventoComponent;
