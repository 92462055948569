import {
    auth,
    db,
    storage,
    googleProvider,
    twitterProvider,
    linkedinProvider,
    microsoftProvider
  } from "../firebaseConfig";
  import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
  import {
    doc,
    setDoc,
    getDoc,
    collection,
    addDoc,
    getDocs,
    serverTimestamp,
    query,
    where,
    arrayUnion,
    updateDoc,
    onSnapshot,
    deleteDoc,
    limit
  } from "firebase/firestore";
  
  export const uploadUserProfileImage = async (uid, file) => {
    const userProfileImageRef = ref(storage, `userProfileImages/${uid}`);
    await uploadBytes(userProfileImageRef, file);
    const imageUrl = await getDownloadURL(userProfileImageRef);
    return imageUrl;
  };
  
  // ... [Resto de funciones]
  
  export {
    auth,
    db,
    storage,
    googleProvider,
    twitterProvider,
    linkedinProvider,
    microsoftProvider,
    serverTimestamp,
    collection,
    addDoc,
    doc,
    setDoc,
    getDoc,
    ref,
    uploadBytes,
    getDownloadURL,
    getDocs,
    query,
    where,
    arrayUnion,
    updateDoc,
    onSnapshot,
    deleteDoc,
    deleteObject,
    limit
  };
  
  export async function saveUserProfileData(userId, data) {
    const userDoc = doc(db, "userProfiles", userId);
    await setDoc(userDoc, data, { merge: true });
  }
  
  export async function getUserProfileData(userId) {
    const userDoc = doc(db, "userProfiles", userId);
    const docSnapshot = await getDoc(userDoc);
    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
      return null;
    }
  }
  
  export function getCurrentUser() {
    return auth.currentUser;
  }
  
  export const checkAvailability = async () => {
    try {
        // Consulta todos los registros con confirmAttendance "Si"
        const attendanceQuery = query(collection(db, 'OsteoProfiles'), where("confirmAttendance", "===", "Si"));
        const attendanceSnapshot = await getDocs(attendanceQuery);
        const attendanceCount = attendanceSnapshot.size;
  
        // Consulta todos los registros donde isSponsored es diferente de "No está patrocinado"
        const sponsoredQuery = query(collection(db, 'OsteoProfiles'), where("isSponsored", "!==", "No está patrocinado"));
        const sponsoredSnapshot = await getDocs(sponsoredQuery);
        const sponsoredCount = sponsoredSnapshot.size;

        const totalRegistered = attendanceCount + sponsoredCount;
  
        // Si la suma es 52 o más, entonces no hay cupos disponibles
        if (totalRegistered >= 200) {
            return { available: false, spotsLeft: 0 };
        }
  
        return { available: true, spotsLeft: 52 - totalRegistered };
  
    } catch (error) {
        console.error("Error checking availability:", error);
        return { available: false, spotsLeft: 0 };
    }
};
export const getDataFromSecondInstanceCollection = async (collectionName) => {
  try {
      const dataQuery = query(collection(db, collectionName));
      const snapshot = await getDocs(dataQuery);
      const data = snapshot.docs.map(doc => doc.data());
      return data;
  } catch (error) {
      console.error(`Error fetching data from ${collectionName}:`, error);
      return [];
  }
};